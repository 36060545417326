import { IonButton, IonInput, IonItem, IonModal } from '@ionic/react';
import React, { Component } from 'react';
import { getConfig } from '../../appConfig.js';
import Basket from '../../lib/basket.js';
import { withTranslation } from '../../lib/translate.js';
import { NormalText, SmallText, Spacer, StrongText, Title } from '../common/index.js';
import Modal from '../modal/index.js';
import './index.css';

class TableBillServiceCharge extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			service_charge: Basket.bill_pay_service_charge || 0,
			service_charge_applied: 0,
			billPayCustomServiceChargeModalOpen: false,
			custom_service_charge: 0,
			customAmountErrorVisible: false,
		};
	}
	componentDidMount() {
		if (this.state.service_charge !== null) {
			Basket.setBillPayServiceCharge(this.state.service_charge);
		}
		this.props.serviceChargeSet(this.state.service_charge);
	}
	setBillPayCustomServiceCharge(value) {
		Basket.setBillPayServiceCharge(null, value * 100);
		this.setState(
			{
				custom_service_charge: value * 100,
				billPayCustomServiceChargeModalOpen: false,
				service_charge: null,
			},
			() => {
				this.props.serviceChargeSet(value * 100);
			},
		);
	}
	setCustomServiceChargeAmount = (value) => {
		if (value >= 0) {
			this.setState({ serviceChargeAmount: value, customAmountErrorVisible: false });
		} else {
			this.setState({ customAmountErrorVisible: true, serviceChargeAmount: 0 });
		}
	};
	setBillPayServiceCharge(e, service_charge) {
		e.stopPropagation();
		document.querySelectorAll('.bill-pay-service-charge-button').forEach((el) => el.classList.remove('is-active'));
		e.target.classList.add('is-active');
		Basket.setBillPayServiceCharge(service_charge);
		this.setState({ service_charge }, () => {
			this.props.serviceChargeSet(service_charge);
		});
	}

	render() {
		const { __ } = this.props;
		return (
			<>
				<div className="bill-pay-service-charge-wrapper">
					<div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
						<StrongText>{__('Tip')}</StrongText>
						<StrongText>{Basket.getBillPayServiceChargeValue()}</StrongText>
					</div>
					<div>
						{getConfig().general.billPayServiceChargeValues.map((el, index) => {
							return (
								<IonButton
									key={index}
									className={`bill-pay-service-charge-button ${this.state.service_charge == el && 'is-active'}`}
									onClick={(e) => this.setBillPayServiceCharge(e, el)}
									color="secondary"
								>
									{el == 0 ? <>{__('No, thanks')}</> : <>{el}%</>}
								</IonButton>
							);
						})}
					</div>
					<Spacer size={1} />
					<div>
						<IonButton expand="block" fill="clear" color="secondary" className="link underlined small-button" onClick={(e) => this.setState({ billPayCustomServiceChargeModalOpen: true })}>
							{__('Custom amount')}
						</IonButton>
						<IonButton expand="block" fill="clear" color="secondary" className="link underlined small-button" onClick={(e) => this.setBillPayServiceCharge(e, 0)}>
							{__('No thanks')}
						</IonButton>
					</div>
				</div>
				{this.state.billPayCustomServiceChargeModalOpen && (
					<Modal
						className="custom-service-charge-modal"
						onDidDismiss={() => this.setState({ billPayCustomServiceChargeModalOpen: false })}
						isOpen={this.state.billPayCustomServiceChargeModalOpen}
					>
						<Title>{__('Add tip')}</Title>
						<Spacer size={1} />
						<div lines="none" className="box-wrapper inline-box-wrapper">
							<div>
								<StrongText>{Basket.getCurrency().label}</StrongText>
							</div>
							<IonItem lines="none" className="custom-amount-field">
								<IonInput
									className="custom-amount-field"
									min="0"
									onIonChange={(e) => this.setCustomServiceChargeAmount(e.target.value)}
									type="number"
									pattern="number"
									inputmode="number"
									placeholder={__('Tip')}
								></IonInput>
							</IonItem>
						</div>
						<Spacer size={1} />
						{this.state.customAmountErrorVisible && (
							<>
								<SmallText className="danger-color">{__('Value can not be negative')}</SmallText>
								<Spacer size={1} />
							</>
						)}
						<IonButton
							disabled={!this.state.serviceChargeAmount || this.state.serviceChargeAmount == 0}
							color="primary"
							expand="block"
							onClick={() => this.setBillPayCustomServiceCharge(this.state.serviceChargeAmount)}
						>
							{__('Add tip')}
						</IonButton>
					</Modal>
				)}
			</>
		);
	}
}

export default withTranslation(TableBillServiceCharge);

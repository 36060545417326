export const FETCH_SUBSCRIPTIONS = 'FETCH_SUBSCRIPTIONS';
export const FETCH_SUBSCRIPTIONS_OK = 'FETCH_SUBSCRIPTIONS_OK';
export const FETCH_SUBSCRIPTIONS_FAIL = 'FETCH_SUBSCRIPTIONS_FAIL';
export const SUBSCRIBE = 'SUBSCRIBE';
export const UNSUBSCRIBE = 'UNSUBSCRIBE';
export const CANCEL_USER_SUBSCRIPTION = 'CANCEL_USER_SUBSCRIPTION';
export const REACTIVATE_USER_SUBSCRIPTION = 'REACTIVATE_USER_SUBSCRIPTION';
export const PAY_MEMBERSHIP = 'PAY_MEMBERSHIP';
export const PAY_MEMBERSHIP_OK = 'PAY_MEMBERSHIP_OK';
export const PAY_MEMBERSHIP_FAILED = 'PAY_MEMBERSHIP_FAILED';
export const SET_SUBSCRIPTION_FLOW = 'SET_SUBSCRIPTION_FLOW';
export const CREATE_SUBSCRIPTION = 'CREATE_SUBSCRIPTION';

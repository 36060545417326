import { IonAlert, IonButton, IonIcon, IonList } from '@ionic/react';
import { checkmarkCircle, checkmarkCircleOutline } from 'ionicons/icons';
import moment from 'moment';
import QRCode from 'qrcode-react';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getConfig } from '../../appConfig';
import AddToWallet from '../../components/addToWallet';
import { NormalText, SmallText, Spacer, StrongText, Title } from '../../components/common';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import { SubscriptionLine } from '../../components/subscription-item';
import Basket from '../../lib/basket';
import { withTranslation } from '../../lib/translate';
import { checkBackgroundColor, forwardTo, getDefaultRoute, isDefined, isEmptyObject, isWebConfig } from '../../lib/utils';
import { getSubscriptions, reactivateSubscription, unsubscribe } from '../../store/subscription/actions';
import './index.css';

const MyMembership = ({ __, subscriptions, active_subscription, getSubscriptions, unsubscribe, reactivateSubscription, navConfig, clientProfile, clientStyles, profile }) => {
	const [style] = useState(clientStyles.colors?.['--okx-dashboard-background']);
	useEffect(() => {
		getSubscriptions();
	}, []);
	if (isEmptyObject(active_subscription)) {
		forwardTo('/membership');
	}
	const [showSwitchMembershipAlert, setShowSwitchMembershipAlert] = useState(false);
	const [showCancelMembershipAlert, setShowCancelMembershipAlert] = useState(false);
	const [showReactivatingMembershipAlert, setShowReactivatingMembershipAlert] = useState(false);

	const handleClick = (active_subscription) => () => {
		if (active_subscription.cancellation_pending) {
			setShowReactivatingMembershipAlert(true);
		} else {
			setShowCancelMembershipAlert(true);
		}
	};

	const cancelMembershipAction = () => {
		setShowCancelMembershipAlert(false);
		unsubscribe();
	};

	const reactivateMembershipAction = () => {
		setShowReactivatingMembershipAlert(false);
		reactivateSubscription();
	};

	const items = subscriptions
		.filter((e) => e.id == active_subscription.id)
		.map((e, idx) => {
			const isChecked = e.id === active_subscription.id;
			const price = e.monthly_cost_inc_vat / 100;
			return SubscriptionLine(__, () => {}, idx, isChecked, e.name, e.short_description, price, e.perks);
		});

	const days_left = moment(active_subscription.next_renewal_date).diff(moment(), 'days');
	const backHandler = () => {
		const defaultRoute = getDefaultRoute(navConfig);
		forwardTo(defaultRoute.path);
	};
	const { hasAddToAppleWallet, hasAddToGoogleWallet } = getConfig().flags;
	return (
		<Loading transparent>
			<Layout showHamburger backHandler={backHandler} hideSecondToolbar={true} color="transparent" headerTitle={__('My Membership')}>
				<div className="absolute-wrapper flex-row-wrapper">
					<div className="box-content membership-card">
						<Title className="centered">
							{clientProfile.buisiness_name} {__('MEMBERSHIP CLUB')}
						</Title>
						<Spacer size={1} />
						<div style={{ flexDirection: 'column' }}>
							<div className="qr-code">
								{isDefined(profile.qr_code) && profile.qr_code !== '' ? (
									<div className="qr-holder">
										<QRCode value={profile.qr_code} size={isWebConfig() ? 150 : 200} />
									</div>
								) : (
									<div>
										<h5>{__('NO QR CODE')}</h5>
									</div>
								)}
							</div>
							{isDefined(profile.qr_code) && profile.qr_code !== '' && (hasAddToAppleWallet || hasAddToGoogleWallet) ? <AddToWallet /> : null}
						</div>
						<div>
							<div>
								<StrongText className="block">{Basket.formatPrice(active_subscription.package_cost / 100, true)}</StrongText>
								<SmallText>{__('per month')}</SmallText>
							</div>
							<div>
								<SmallText className="block">{active_subscription.cancellation_pending ? __('Ends on') : __('Renews on')}</SmallText>
								<StrongText>{moment(active_subscription.next_renewal_date).format('DD/MM')}</StrongText>
							</div>
						</div>
					</div>
					<div className="membership-card-content box-content">
						<div>
							<SmallText>{__('Subscription active')}</SmallText>
							<IonIcon icon={checkmarkCircleOutline} color="success" />
						</div>
						{active_subscription.description && (
							<>
								<NormalText>{active_subscription.description}</NormalText>
								<Spacer size={1} />
							</>
						)}

						<div className="subscription-perks">
							{active_subscription.perks?.map((el) => {
								return (
									<div>
										<IonIcon icon={checkmarkCircleOutline} color="primary" />

										<NormalText>{el.name}</NormalText>
									</div>
								);
							})}
						</div>
					</div>
					<Spacer />
					<IonButton
						color="danger"
						fill="clear"
						disabled={active_subscription === null}
						onClick={handleClick(active_subscription)}
						expand="block"
						className={'underlined link ' + (active_subscription.cancellation_pending ? 'greyed' : '')}
					>
						{active_subscription.cancellation_pending ? __('Cancellation Pending - Reactivate?') : __('Cancel Membership')}
					</IonButton>
				</div>

				<IonAlert
					isOpen={showSwitchMembershipAlert}
					header={__('Confirm Change')}
					message={__('Are you sure you wish to change your membership? Your new package will start at the next scheduled renewal date.')}
					buttons={[
						{ text: __('Cancel'), handler: () => setShowSwitchMembershipAlert(false) },
						{ text: __('OK'), handler: () => setShowSwitchMembershipAlert(false) },
					]}
				/>
				<IonAlert
					isOpen={showCancelMembershipAlert}
					header={__('Are you sure')}
					message={__('By cancelling your membership it will not renew on the scheduled date.')}
					buttons={[
						{ text: __('Back'), handler: () => setShowCancelMembershipAlert(false) },
						{ text: __('OK'), handler: cancelMembershipAction },
					]}
				/>
				<IonAlert
					isOpen={showReactivatingMembershipAlert}
					header={__('Are you sure')}
					message={__('By reactivating your membership it will renew on the scheduled renewal date.')}
					buttons={[
						{
							text: __('Back'),
							handler: () => setShowReactivatingMembershipAlert(false),
						},
						{ text: __('OK'), handler: reactivateMembershipAction },
					]}
				/>
			</Layout>
		</Loading>
	);
};

const mapStateToProps = (store) => ({
	subscriptions: store.subscription.subscriptions,
	active_subscription: store.profile.profile.active_subscription,
	navConfig: store.common.navConfig,
	clientProfile: store.common.clientProfile,
	clientStyles: store.common.clientStyles,
	profile: store.profile.profile,
});

const mapDispatchToProps = {
	getSubscriptions,
	unsubscribe,
	reactivateSubscription,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(MyMembership));

import React from 'react';
import { IonList, IonItem, IonRadioGroup, IonRadio, IonLabel, IonButton, IonText } from '@ionic/react';
import Loading from '../../components/spinner';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import { Title, SmallText, Spacer, Sectiontitle } from '../../components/common';
import { connect } from 'react-redux';
import { Capacitor } from '@capacitor/core';
import Basket from '../../lib/basket';
// import DrawPayButton from '../../components/drawPayButton';
import CheckoutPay from './CheckoutMembershipPay';
import { forwardTo, goBack, isWebConfig } from '../../lib/utils';
import { payMembership, createSubscription } from '../../store/subscription/actions';
import { loading, showToast } from '../../store/actions';
import './index.css';

const { getMobile, setMobile } = Basket;

const isWeb = () => Capacitor.platform === 'web';

class CheckoutMembership extends React.Component {
	constructor(props) {
		super(props);
		if (!getMobile() && this.props.profile && this.props.profile.mobile) {
			setMobile(this.props.profile.mobile);
		}
		this.state = {
			selectedCard: null,
		};
	}

	componentDidMount() {
		const { profile, selectedSubscription } = this.props;
		if (profile.cardToken) {
			this.changeSelectedPaymentCard(profile.cardToken);
		}
		const membership = this.props.subscriptions[selectedSubscription?.idx];
		Basket.setSubscription(membership);
	}

	componentDidUpdate(prevProps) {
		if (prevProps.profile.cardToken !== this.props.profile.cardToken) {
			this.changeSelectedPaymentCard(this.props.profile.cardToken);
		}
	}

	changeSelectedPaymentCard = (cardId) =>
		this.setState({ selectedCard: cardId }, () => {
			Basket.changeSelectedCard(cardId);
		});

	backHandler = () => {
		if (this.props.location && this.props.location.state && this.props.location.state.skipContactDetails) {
			forwardTo('/order-summary', { skipBackToThePreviousPage: true });
		} else if (this.props.location.pathname === '/checkout') {
			forwardTo('/order');
		} else {
			goBack();
		}
	};

	handleClickStripeMembership = (token, cardName) => {
		const subscriptionIdx = this.props.selectedSubscription?.idx;
		if (subscriptionIdx < 0 || subscriptionIdx > this.props.subscriptions) {
			this.props.showToast(this.props.__('Error cannot find membership'));
			return;
		}
		// DO GOOGLE OR APPLE PAY
		this.props.createSubscription({
			id: this.props.selectedSubscription.id,
			token: token.id,
			cardName: cardName,
		});
	};

	handleBuyMembership = () => {
		this.props.dispatch(loading(true));
		const subscriptionIdx = this.props.selectedSubscription?.idx;
		if (subscriptionIdx < 0 || subscriptionIdx > this.props.subscriptions) {
			this.props.showToast(this.props.__('Error cannot find membership'));
			this.props.dispatch(loading(false));
			return;
		}
		this.props.createSubscription({
			id: this.props.selectedSubscription.id,
			existingCardToken: this.state.selectedCard,
		});
	};

	render() {
		const { __, cards, location, clientSecret, dispatch } = this.props;

		return (
			<Loading transparent>
				<Layout color="transparent" title={__('Checkout')} backHandler={this.backHandler} hideSecondToolbar={true}>
					<div className="flex-row-wrapper absolute-content">
						<Title className="web-only">{__('Checkout')}</Title>
						{/* <IonText>{__('Use saved payment card')}</IonText> */}

						<Spacer size={1} />
						<div className="scrollable-y">
							<IonList lines="none" className="box-wrapper">
								<IonRadioGroup onIonChange={(e) => this.changeSelectedPaymentCard(e.detail.value)} value={this.state.selectedCard}>
									{cards
										.reduce((acc, item) => {
											if (!acc.find((el) => el.fingerprint === item.fingerprint)) {
												acc.push(item);
											}
											return acc;
										}, [])
										.map((card) => {
											const { id, last4, brand, exp_month, exp_year, name } = card;

											return (
												<IonItem key={id}>
													<div tabIndex="-1"></div>
													<IonRadio
														color={isWebConfig() ? 'primary' : 'white'}
														slot="start"
														value={id}
														onIonSelect={() => {
															this.setState({ selectedCard: id }, () => {
																Basket.changeSelectedCard(id);
															});
														}}
													/>
													<IonLabel className="ion-text-wrap">
														<IonText className="single-item bold">{name}</IonText>
														<Sectiontitle className="no-margin">•••• •••• •••• {last4}</Sectiontitle>
														<SmallText>
															{__(brand)} - {exp_month}/{exp_year}
														</SmallText>
													</IonLabel>
												</IonItem>
											);
										})}
								</IonRadioGroup>
							</IonList>
							<IonButton fill="clear" color="primary" className="link underlined" onClick={() => forwardTo('/card-add', { addCardFromMembership: true })}>
								{__('Add New Payment Card')}
							</IonButton>
							{/* <IonButton fill="clear" color="dark" className="link underlined" onClick={ () => forwardTo('/card-add', { addCardFromCheckout: false }) }>{ __((cards.length > 0 ? 'Or add another' : 'Add ') + ' payment card') }</IonButton> */}
						</div>
						<div className="flex-min">
							{/* Disable stripe for membership */}
							{isWeb() ? (
								<CheckoutPay handleClick={this.handleClickStripeMembership} clientSecret={clientSecret} dispatch={dispatch} __={__} location={location} />
							) : (
								//  <DrawPayButton
								//   __={__}
								//   googlePayClient={window.googlePayClient}
								//   isSubscription={true}
								// />
								<></>
							)}
							<IonButton color="primary" disabled={cards.length === 0 || !this.state.selectedCard} onClick={this.handleBuyMembership} expand="block">
								{__('Confirm Membership')}
							</IonButton>
						</div>
					</div>
				</Layout>
			</Loading>
		);
	}
}

const mapStateToProps = (store) => ({
	cards: store.orders.cards || [],
	profile: store.profile.profile || {},
	clientSecret: store.orders.clientSecret,
	selectedSubscription: store.orders.selectedSubscription,
	subscriptions: store.subscription.subscriptions,
});

const mapDispatchToProps = {
	showToast,
	payMembership,
	createSubscription,
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation(CheckoutMembership));

import React, { Component } from 'react';
import { withTranslation } from '../../lib/translate.js';
import { deepIsDefined, isDefined } from '../../lib/utils.js';
import { connect } from 'react-redux';
import BigNumber from 'bignumber.js';
import Basket from '../../lib/basket';
import { getConfig } from '../../appConfig.js';
import Incrementer from '../incrementer/index.js';
import './index.css';
import { NormalText } from '../common/index.js';
import { IonButton } from '@ionic/react';

class PointsRedeemBox extends Component {
	constructor(props) {
		super(props);
		this.state = {
			points: Basket.getAppliedPoints(),
		};
	}
	componentDidMount() {
		const points = Basket.getAppliedPoints();
		if (points > 0) {
			this.setState({ points });
		}
	}
	getRedeemPointsMin = () => parseInt(getConfig().general.redeemPointsMin) || 50;

	getDefaultPoints = () => {
		const minimumPoints = this.getRedeemPointsMin();
		const availableBalance = this.props.availableBalance;
		const orderValue = new BigNumber(Basket.getTotal()).times(100).toNumber() + Basket.getAppliedPoints();
		let defaultPoints = 0;
		if (availableBalance >= minimumPoints && orderValue >= minimumPoints) {
			defaultPoints = availableBalance > orderValue ? orderValue : availableBalance;
		}
		return defaultPoints;
	};
	onIncrementerUpdate = (points) => {
		if (points < this.state.points && points < this.getRedeemPointsMin()) {
			points = 0;
		}
		if (points > this.state.points && points < this.getRedeemPointsMin()) {
			points = this.getRedeemPointsMin();
		}
		const shouldScroll = this.state.points == 0 && points !== 0;
		this.setState({ points }, () => {
			Basket.applyPoints(points, this.props.availableBalance);
			if (shouldScroll) {
				setTimeout(() => {
					document.querySelector('.order-summary-points-wrapper').scrollIntoView();
				}, 0);
			}
		});
	};
	render() {
		const { __ } = this.props;
		let availableBalance = this.props.availableBalance;
		const { points } = this.state;
		const step = getConfig().general.redeemPointsStep || 50;
		const basketTotalInCents = new BigNumber(Basket.getTotal()).times(100).toNumber() + Basket.getAppliedPoints();
		let limit = 0;
		if (!isDefined(availableBalance) && availableBalance === null) {
			availableBalance = 0;
		} else {
			limit = parseInt(availableBalance / step) * step;
		}
		let pointsLimitBasedOnBasket = basketTotalInCents;
		if (pointsLimitBasedOnBasket > 0) {
			if (basketTotalInCents <= availableBalance) {
				limit = basketTotalInCents;
			} else {
				pointsLimitBasedOnBasket = parseInt(pointsLimitBasedOnBasket / step) * step;
			}
		}
		return (
			<div className="points-redeem-box-wrapper ">
				<div>
					<NormalText>{`${__('You have')} ${availableBalance} ${__('loyalty points')}`}</NormalText>
					<IonButton onClick={() => this.onIncrementerUpdate(this.getDefaultPoints())} className="link underlined">
						{__('apply max')}
					</IonButton>
				</div>
				<div>
					<Incrementer maxLimit={this.getDefaultPoints()} onUpdate={this.onIncrementerUpdate} quantity={points} step={step} allowNegative={false} minLimit={0} />
					<NormalText>{Basket.formatPrice(points / 100)}</NormalText>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (store) => {
	return {
		availableBalance: deepIsDefined(store, 'profile.profile.available_balance') ? store.profile.profile.available_balance : 0,
	};
};
export default connect(mapStateToProps)(withTranslation(PointsRedeemBox));

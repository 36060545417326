import {
	SET_RESTAURANT_PROP,
	BEFORE_SHOW_TIME_PICKER,
	BEFORE_CLOSE_TIME_PICKER,
	CLEAR_IKENOO_MENU,
	SAVE_DELIVERY_DATA_TEMPORARILY,
	PASSED_ORDER,
	SET_TABLE_NUMBERS,
	SET_TABLE_DATA,
} from './constants';

let initialState = {
	restaurants: [],
	rewards: [],
	ikentooMenu: {},
	ikentooMenusForLocation: [],
	itemAllergens: [],
	isShowTimePicker: false,
	tableData: {},
	selectedRestaurant: {},
	selectedTable: 0,
	tableNumbers: {},
};

function reducer(state = initialState, action) {
	switch (action.type) {
		case SET_RESTAURANT_PROP:
			return { ...state, [action.key]: action.value };
		case BEFORE_SHOW_TIME_PICKER:
			return { ...state, isShowTimePicker: true };
		case BEFORE_CLOSE_TIME_PICKER:
			return { ...state, isShowTimePicker: false };
		case CLEAR_IKENOO_MENU:
			return { ...state, ikentooMenu: {} };
		case SAVE_DELIVERY_DATA_TEMPORARILY:
			return { ...state, tempData: action.tempData };
		case PASSED_ORDER:
			return { ...state, isPassedOrder: action.isPassedOrder };
		case SET_TABLE_NUMBERS:
			return { ...state, tableNumbers: action.value };
		case SET_TABLE_DATA:
			return { ...state, [action.key]: action.value };
		default:
			return state;
	}
}

export default reducer;

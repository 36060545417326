import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { forwardTo } from '../../lib/utils';
import { setMenuSubscriptionFlow } from '../../store/subscription/actions';
import { setOrderTypeMembership } from '../../store/orders/actions';

const ChooseSubscriptionMenu = ({ active_subscription, setMenuSubscriptionFlow, setOrderTypeMembership }) => {
	const page = active_subscription && active_subscription.id ? '/my-membership' : '/membership';
	useEffect(() => {
		setMenuSubscriptionFlow();
		setOrderTypeMembership();
		forwardTo(page, { fromChooseSubscriptionMenu: true });
	}, [page]);
	return <></>;
};

const stateToProps = (store) => ({
	active_subscription: store.profile.profile.active_subscription,
});

const dispatchToProps = {
	setMenuSubscriptionFlow,
	setOrderTypeMembership,
};

export default connect(stateToProps, dispatchToProps)(ChooseSubscriptionMenu);

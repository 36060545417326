import { FETCH_SUBSCRIPTIONS_OK, SET_SUBSCRIPTION_FLOW } from './constants';

const initialState = {
	subscriptions: [],
	flow: 'order_flow',
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case FETCH_SUBSCRIPTIONS_OK:
			return { ...state, [action.key]: action.value.data };
		case SET_SUBSCRIPTION_FLOW:
			return { ...state, flow: action.value };
		default:
			return state;
	}
};

export default reducer;

import { getEnv } from './lib/env.js';

(function (d, s, i) {
	let f = d.getElementsByTagName(s)[0];
	let j = d.createElement(s);
	j.async = true;
	j.src = `https://maps.googleapis.com/maps/api/js?key=${i}&libraries=places`;
	f.parentNode.insertBefore(j, f);
})(document, 'script', getEnv('GOOGLE_API_KEY'));

let appConfig = {
	theme: {
		nav: {
			label: 'Hybrid Direct',
		},
		showHeaderOnAuthRoutes: true,
		showInputIconsOnLogin: false,
		routesWithoutStaticHeader: ['/item-details'],
	},
	services: {
		google_maps: {
			google_api_key: getEnv('GOOGLE_API_KEY'),
		},
		// stripe_key: 'pk_test_SfLlfP41LhHVIG2vrA95ZrFt' //Kole
	},
	api: {
		// baseURL: 'http://192.168.0.26:8000', //preda
		baseURL: getEnv('BASE_URL'),
		// baseURL: 'https://hybrid-master.herokuapp.com',
		wordPressBaseURL: 'https://hybridapp.co.uk',
		nativeWallet: getEnv('NATIVE_WALLET_API'),
	},
	general: {
		appExitRoutes: ['/', '/home', '/dashboard'],
		authRoutes: ['/login', '/register', '/reset-password'],
		isReduxDevToolsOn: true,
		isWebPlatform: false,
		// Minimum time between current time and first avalaible time slot
		minTimeDiff: 5,
		routesWithoutBackButton: ['/dashboard', '/locations', '/social'],
		// default_country_code: 'UK',
	},
	appType: {
		hasOrdering: true,
		hasLoyalty: true,
		hasEmailValidationEnabled: true,
	},
	configS3: {
		imageNamePrefix: 'hybrid_direct_profile_image_',
		bucketName: 'hybrid-apps-profile-images',
		region: 'eu-west-1',
	},
	firebaseConfig: {
		apiKey: getEnv('FIREBASE_API_KEY'),
		authDomain: getEnv('FIREBASE_AUTH_DOMAIN'),
		databaseURL: getEnv('FIREBASE_DATABASE_URL'),
		projectId: getEnv('FIREBASE_PROJECT_ID'),
		storageBucket: getEnv('FIREBASE_STORAGE_BUCKET'),
		messagingSenderId: getEnv('FIREBASE_MESSAGING_SENDER_ID'),
		appId: getEnv('FIREBASE_APP_ID'),
		measurementId: getEnv('FIREBASE_MEASUREMENT_ID'),
	},
	socialLogin: {
		googleClientId: getEnv('GOOGLE_CLIENT_ID') || '',
		appleClientId: getEnv('APPLE_CLIENT_ID') || '',
	},
	payment: 'stripe',
};

export const updateConfig = (newConfig) => (appConfig = newConfig);

export const getConfig = () => appConfig;

export default appConfig;

import { getConfig } from '../../appConfig';
import { isDefined } from '../../lib/utils';
import {
	CLEAR_GIFT_VOUCHER_DATA,
	CLEAR_REDEEMED_GIFT_VOUCHER,
	CLEAR_SENT_GIFT_VOUCHERS,
	DRIVER_COUNTDOWN,
	SET_BILL_STATUS,
	SET_DELIVERY_ADDRESS,
	SET_DELIVERY_OPTION,
	SET_DELIVERY_TIME,
	SET_GIFT_VOUCHER_DATA,
	SET_LOCATION_CODE_DATA,
	SET_ORDERS_PROP,
	SET_ORDER_TYPE,
	SET_PAYMENT_TOKEN,
	SET_PICK_UP_POINT,
	SET_POSTCODE_DATA,
	SET_REDEEMED_GIFT_VOUCHER,
	SET_SCROLL_TOP,
	SET_SELECTED_SUBSCRIPTION,
	SET_SENT_GIFT_VOUCHERS,
	BOOK_DELIVERY_DRIVER,
	STORE_DELIVERY_ADDRESS,
	STORE_ITEM_WEB,
	STORE_PICKUP_POINT,
	UPDATE_PAYMENT_INFO,
} from './constants';

let initialState = {
	history: [],
	basketUpdated: null,
	cards: [],
	orderHistory: [],
	scrollTop: 0,
	deliveryOption: null,
	deliveryAddress: null,
	deliveryTime: null,
	pickUpPoint: null,
	paymentToken: null,
	checkedCodeData: [],
	checkedLocationCodeData: [],
	storedDeliveryAddress: null,
	storedPickUpPoint: null,
	storedItemWeb: null,
	removeAddressModal: false,
	cancelOrderModal: false,
	clientSecret: '',
	giftVoucherData: null,
	sentGiftVouchers: [],
	redeemedGiftVoucher: null,
	billStatus: {},
	driverCountdown: 0,
};

function reducer(state = initialState, action) {
	switch (action.type) {
		case SET_ORDERS_PROP:
			return {
				...state,
				[action.key]: isDefined(action.merge) && action.merge ? { ...state[action.key], ...action.value } : action.value,
			};
		case SET_SCROLL_TOP:
			return { ...state, scrollTop: action.value };
		case SET_DELIVERY_OPTION:
			return { ...state, deliveryOption: action.deliveryOption };
		case SET_DELIVERY_ADDRESS:
			return { ...state, deliveryAddress: action.deliveryAddress };
		case SET_DELIVERY_TIME:
			return { ...state, deliveryTime: action.deliveryTime };
		case SET_PICK_UP_POINT:
			return { ...state, pickUpPoint: action.pickUpPoint };
		case SET_PAYMENT_TOKEN:
			return { ...state, paymentToken: action.token };
		case SET_POSTCODE_DATA:
			return { ...state, checkedCodeData: action.checkedCodeData.data };
		case STORE_DELIVERY_ADDRESS:
			return { ...state, storedDeliveryAddress: action.deliveryAddress };
		case STORE_PICKUP_POINT:
			return { ...state, storedPickUpPoint: action.pickUpPoint };
		case SET_ORDER_TYPE:
			return { ...state, orderType: action.value };
		case SET_SELECTED_SUBSCRIPTION:
			return { ...state, selectedSubscription: action.value };
		case SET_LOCATION_CODE_DATA:
			return { ...state, checkedLocationCodeData: action.checkedLocationCodeData.data };
		case STORE_ITEM_WEB:
			return { ...state, storedItemWeb: action.item };
		case UPDATE_PAYMENT_INFO:
			return { ...state, paymentInfo: action.paymentInfo };
		case SET_GIFT_VOUCHER_DATA:
			return { ...state, giftVoucherData: action.value };
		case CLEAR_GIFT_VOUCHER_DATA:
			return { ...state, giftVoucherData: null };
		case SET_SENT_GIFT_VOUCHERS:
			return { ...state, sentGiftVouchers: action.value };
		case SET_REDEEMED_GIFT_VOUCHER:
			return { ...state, redeemedGiftVoucher: action.value };
		case CLEAR_REDEEMED_GIFT_VOUCHER:
			return { ...state, redeemedGiftVoucher: null };
		case CLEAR_SENT_GIFT_VOUCHERS:
			return { ...state, sentGiftVouchers: [] };
		case SET_BILL_STATUS:
			return { ...state, billStatus: action.billStatus };
		case BOOK_DELIVERY_DRIVER:
			return {
				...state,
			};
		case DRIVER_COUNTDOWN:
			return {
				...state,
				driverCountdown: action.payload,
			};
		default:
			return state;
	}
}

export default reducer;

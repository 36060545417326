import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NormalText, Spacer, Title } from '../../components/common';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import { withTranslation } from '../../lib/translate';
import NoData from '../../components/noData';
import { IonButton, IonCheckbox, IonLabel, IonItem } from '@ionic/react';
import { forwardTo } from '../../lib/utils';
import { createSubscription } from '../../store/subscription/actions';

import './index.css';

class TermsSubsciptionPackage extends Component {
	constructor(props) {
		super(props);
		this.state = { checked: false };
	}

	handleAcceptTerms = () => this.setState({ checked: !this.state.checked });

	handleContinue = () => {
		const subscriptionIdx = this.props.selectedSubscription?.idx;
		if (subscriptionIdx < 0 || subscriptionIdx > this.props.subscriptions) {
			this.props.showToast(this.props.__('Error cannot find membership'));
			return;
		}
		this.props.createSubscription({
			id: this.props.selectedSubscription.id,
			existingCardToken: this.state.selectedCard,
		});
	};

	render() {
		const { __, subscription } = this.props;
		const terms = subscription ? subscription.terms : null;
		return (
			<Loading>
				<Layout hideSecondToolbar color="transparent" headerTitle={__('Terms & conditions')}>
					<div className="absolute-content flex-row-wrapper">
						<div className="scrollable-y">
							<Title>{__('Terms & conditions')}</Title>
							<Spacer size={1} />
							{terms ? <div className="terms-wrapper" dangerouslySetInnerHTML={{ __html: terms }} /> : <NoData />}
						</div>
						<div className="flex-min" style={{ marginTop: '20px' }}>
							<IonItem lines="none" class="box-content">
								<IonCheckbox color="primary" slot="start" checked={this.state.checked} onClick={this.handleAcceptTerms} />
								<NormalText>{__('I accept the terms & conditions')}</NormalText>
							</IonItem>
							<Spacer size={1} />
							<IonButton color="primary" disabled={!this.state.checked} onClick={this.handleContinue} expand="block" className={'checkout-btn'}>
								{__('Continue')}
							</IonButton>
						</div>
					</div>
				</Layout>
			</Loading>
		);
	}
}

const stateToProps = (state) => {
	return {
		selectedSubscription: state.orders.selectedSubscription,
		subscriptions: state.subscription.subscriptions,
		subscription: state.subscription.subscriptions[state.orders.selectedSubscription?.idx],
	};
};

const dispatchToProps = {
	createSubscription,
};
export default connect(stateToProps, dispatchToProps)(withTranslation(TermsSubsciptionPackage));

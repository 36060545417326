import {
	GET_RESTAURANTS,
	GET_REWARDS,
	GET_IKENTOO_MENU,
	GET_IKENTOO_MENUS_FOR_LOCATION,
	BEFORE_CLOSE_TIME_PICKER,
	BEFORE_SHOW_TIME_PICKER,
	GET_RESTAURANTS_SNOOZED_DATA,
	CLEAR_IKENOO_MENU,
	SAVE_DELIVERY_DATA_TEMPORARILY,
	PASSED_ORDER,
	GET_RESTAURANT_TABLE,
	GET_TABLE_NUMBER,
	SET_TABLE_NUMBERS,
	GET_IKENTOO_MENUS_PICK_UP_AT_COUNTER,
	GET_TAX,
	GET_TAX_DATA,
} from './constants';

export const getRestaurants = () => ({ type: GET_RESTAURANTS });

export const getRewards = () => ({ type: GET_REWARDS });

export const getIkentooMenu = (menuId, businessLocationId, redirect = true) => ({
	type: GET_IKENTOO_MENU,
	menuId,
	businessLocationId,
	redirect,
});

export const getIkentooMenusForLocation = (businessLocationId, additionalData = {}, isDelivery, disableGettingFirstMenu = false) => ({
	type: GET_IKENTOO_MENUS_FOR_LOCATION,
	businessLocationId,
	additionalData,
	isDelivery,
	disableGettingFirstMenu,
});

export const beforeShowTimePicker = () => ({ type: BEFORE_SHOW_TIME_PICKER });

export const beforeCloseTimePicker = () => ({ type: BEFORE_CLOSE_TIME_PICKER });

export const getRestaurantSnoozeData = () => ({ type: GET_RESTAURANTS_SNOOZED_DATA });

export const clearIkentooMenu = () => ({ type: CLEAR_IKENOO_MENU });

export const saveDeliveryDataTemporarily = (tempData) => ({
	type: SAVE_DELIVERY_DATA_TEMPORARILY,
	tempData,
});

export const passedTheOrder = (isPassedOrder) => ({ type: PASSED_ORDER, isPassedOrder });

export const getRestaurantTable = (restaurant, table, is_guest = false) => ({
	type: GET_RESTAURANT_TABLE,
	restaurant,
	table,
	is_guest,
});
export const getTableNumber = () => ({ type: GET_TABLE_NUMBER });
export const setTableNumbers = () => ({ type: SET_TABLE_NUMBERS });
export const getIkentooMenusPickUpAtCounter = (businessLocationId, pickTime, json_time_selector, redirect) => ({
	type: GET_IKENTOO_MENUS_PICK_UP_AT_COUNTER,
	businessLocationId,
	pickTime,
	json_time_selector,
	redirect,
});
export const getTax = (data) => ({
	type: GET_TAX,
	data,
});
export const getTaxData = (uuid) => ({
	type: GET_TAX_DATA,
	uuid,
});

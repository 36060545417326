import { IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react';
import React, { Component } from 'react';
import Basket from '../../lib/basket.js';
import { withTranslation } from '../../lib/translate.js';
import { NormalText, SmallText, StrongText } from '../common/index.js';
import { chevronDown, chevronUp } from 'ionicons/icons';

import './index.css';
import { connect } from 'react-redux';
import { SET_COMMON_PROP } from '../../store/constants.js';
import { isEmptyObject } from '../../lib/utils.js';

class TableItemsOvewview extends Component {
	constructor(props) {
		super(props);
		this.state = {
			tableItemsVisible: false,
			tableData: this.props.tableData,
		};
	}
	calculateServiceCharge = () => {
		let total = 0;
		let totalPlusServiceCharge = 0;
		for (let i = 0; i < Basket.table_items.length; i++) {
			if (Basket.table_items[i].grossUnitAmount !== Basket.table_items[i].unitAmount) {
				total = total + parseFloat(Basket.table_items[i].grossUnitAmount);
				totalPlusServiceCharge = totalPlusServiceCharge + parseFloat(Basket.table_items[i].unitAmount) + parseFloat(Basket.table_items[i].discountedAmount);
			}
			for (let j = 0; j < Basket.table_items[i].subLineItems.length; j++) {
				if (Basket.table_items[i].subLineItems[j].grossUnitAmount !== Basket.table_items[i].subLineItems[j].unitAmount) {
					total = total + parseFloat(Basket.table_items[i].subLineItems[j].grossUnitAmount);
					totalPlusServiceCharge = totalPlusServiceCharge + parseFloat(Basket.table_items[i].subLineItems[j].unitAmount) + parseFloat(Basket.table_items[i].subLineItems[j].discountedAmount);
				}
			}
		}
		const automaticServiceChargeValue = totalPlusServiceCharge - total;
		const num = ((parseFloat(totalPlusServiceCharge) - parseFloat(total)) / parseFloat(total)) * 100;
		const automaticServiceChargePercentage = `${Math.floor(num * 10) / 10}%`;
		return {
			automaticServiceChargePercentage,
			automaticServiceChargeValue,
		};
	};
	componentDidMount() {
		const { automaticServiceChargeValue, automaticServiceChargePercentage } = this.calculateServiceCharge();
		this.setState({ automaticServiceChargeValue, automaticServiceChargePercentage });
	}
	componentDidUpdate(prevProps) {
		if (
			this.props.tableData.total_due !== prevProps.tableData.total_due ||
			JSON.stringify(this.props.tableData.bill_data?.salesEntries) !== JSON.stringify(prevProps.tableData.bill_data?.salesEntries)
		) {
			this.setState({ tableData: this.props.tableData });
			Basket.setBillPayData(this.props.tableData);
			Basket.setTablePaymentAmount(this.props.tableData.total_due);
			const { automaticServiceChargeValue, automaticServiceChargePercentage } = this.calculateServiceCharge();
			this.setState({ automaticServiceChargeValue, automaticServiceChargePercentage });
		}
	}
	calculateItemPrice(item) {
		const sublinePrice = item.subLineItems.map((el) => parseFloat(el.grossUnitAmount)).reduce((partialSum, a) => partialSum + a, 0);
		return Basket.formatPrice(parseFloat(item.grossUnitAmount) + sublinePrice, true);
	}
	getAppliedDiscount(item) {
		const sublinePriceDiscount = item.subLineItems.map((el) => parseFloat(el.discountedAmount)).reduce((partialSum, a) => partialSum + a, 0);
		return parseFloat(item.discountedAmount) + sublinePriceDiscount;
	}
	render() {
		const { __, isTableItemsOverviewVisible, dispatch } = this.props;
		return (
			<IonGrid className={`${isTableItemsOverviewVisible ? 'items-overwiew-opened' : ''} box-wrapper space-between pointer table-items-wrapper`}>
				<IonRow
					style={{ width: '100%' }}
					onClick={() =>
						dispatch({
							type: SET_COMMON_PROP,
							key: 'isTableItemsOverviewVisible',
							value: !isTableItemsOverviewVisible,
						})
					}
				>
					<IonCol>
						<StrongText>{isTableItemsOverviewVisible ? __('Hide items') : __('View items')}</StrongText>
					</IonCol>
					<IonCol className="righted">
						<IonIcon icon={isTableItemsOverviewVisible ? chevronUp : chevronDown}></IonIcon>
					</IonCol>
				</IonRow>
				{isTableItemsOverviewVisible && (
					<>
						{Basket.table_items.map((el) => (
							<>
								<IonRow style={{ width: '100%', paddingBottom: el.subLineItems.length > 0 || this.getAppliedDiscount(el) > 0 ? '0' : '10px' }}>
									<IonCol size="8">
										<NormalText>{el.itemName}</NormalText>
									</IonCol>
									<IonCol size="4" className="righted">
										<NormalText>{this.calculateItemPrice(el)}</NormalText>
									</IonCol>
								</IonRow>
								{this.getAppliedDiscount(el) > 0 && (
									<IonRow style={{ width: '100%', padding: '0 10px 0 20px', borderTop: 'none', paddingBottom: el.subLineItems.length == 0 ? '10px' : '0' }}>
										<IonCol size="8">
											<NormalText className="light-color">{`${__('Discount')} `}</NormalText>
										</IonCol>
										<IonCol size="4" className="righted">
											<NormalText className="light-color">-{Basket.formatPrice(this.getAppliedDiscount(el), true)}</NormalText>
										</IonCol>
									</IonRow>
								)}
								{el.subLineItems.map((subitem, i) => (
									<IonRow key={`sub-${i}`} style={{ width: '100%', padding: '0 20px', borderTop: 'none', paddingBottom: i == el.subLineItems.length - 1 ? '10px' : '0' }}>
										<IonCol size="8">
											<NormalText className="light-color">
												{`${subitem.itemName} ${parseFloat(subitem.grossUnitAmount) > 0 && `(${Basket.formatPrice(subitem.grossUnitAmount)})`}`}
											</NormalText>
										</IonCol>
									</IonRow>
								))}
							</>
						))}
						{this.state.automaticServiceChargeValue > 0 && (
							<IonRow>
								<IonCol>{__('Service charge')}</IonCol>
								<IonCol className="righted">{Basket.formatPrice(this.state.automaticServiceChargeValue)}</IonCol>
							</IonRow>
						)}
						<IonRow>
							<IonCol>
								<StrongText className="bold ">{__('Total')}</StrongText>
							</IonCol>
							<IonCol className="righted">
								<StrongText className="bold ">{Basket.getBillTotal()}</StrongText>
							</IonCol>
						</IonRow>
					</>
				)}
			</IonGrid>
		);
	}
}
const stateToProps = (state) => {
	const { isTableItemsOverviewVisible } = state.common;
	const { tableData, restaurants } = state.restaurants;
	return {
		isTableItemsOverviewVisible,
		tableData,
		restaurants,
	};
};
export default connect(stateToProps)(withTranslation(TableItemsOvewview));

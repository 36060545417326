import { IonAlert, IonButton, IonCheckbox, IonIcon, IonInput, IonItem, IonLabel, IonList, IonRadio, IonRadioGroup } from '@ionic/react';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getConfig } from '../../appConfig';
import { FieldError, NormalText, SmallText, Spacer, StrongText, Title } from '../../components/common';
import Layout from '../../components/layout';
import Mobiscroll from '../../components/mobiscroll';
import NoData from '../../components/noData';
import Loading from '../../components/spinner';
import api from '../../lib/api';
import Basket from '../../lib/basket';
import moment from '../../lib/moment';
import { withTranslation } from '../../lib/translate';
import { checkForDeliveryOption, deepCopy, forwardTo, getDefaultRoute, goBack, isDefined, isObject, isTimeAvaibleInMenu, isWebConfig, parseAllergenData } from '../../lib/utils';
import { getIkentooMenu, getIkentooMenusForLocation, getRestaurants, setCommonModal, setDeliveryOption, setMyLocation, showToast } from '../../store/actions';
import { SET_RESTAURANT_PROP } from '../../store/constants';
import { filterMenu } from '../../store/restaurants/sagas';
import { formatDataForTime, getMenusForSelectedTime, isStoreOpened } from '../clickAndCollect';
import './index.css';
import { warningOutline } from 'ionicons/icons';

const { SelectOption } = Mobiscroll;
const tableSelectPlaceholder = 'Select table';

class OrderToTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedRestaurant: props.location.state && props.location.state.selectedRestaurant ? props.location.state.selectedRestaurant.restaurant_id : null,
			error: '',
			isLocationAllowed: false,
			tableNumber: props.location?.state?.saveOrderData
				? Basket.getTableNumber()
				: props.location?.state?.selectedRestaurant
				? props.location.state.selectedRestaurant.table_name
				: getConfig().flags.tableNumberPicker
				? null
				: '',
			showTableNumber: true,
			totalTableNumbers: [],
			tableNumberPicker: getConfig().flags.tableNumberPicker || false,
			selectedRestaurantFull: null,
			noTableRestaurants: false,
			isSubmitRestaurantAlertOpen: false,
			useSearchParams: false,
			forwardToOrdering: false,
			checkTablePlan: getConfig().flags.checkTablePlan || false,
			tableNumberError: false,
		};
	}
	formatMultiVenueTableNumbers = () => {
		const { __ } = this.props;
		const tablePlan = getConfig().tablePlan || [];
		const tables = tablePlan.map((zone) => zone.tables.map((table) => ({ value: table.id, text: `Table ${table.table_number}`, group: zone.name }))).flat();
		return tables;
	};
	componentDidMount() {
		const searchParams = new URLSearchParams(this.props.location.search);
		if (Basket.getDeliveryOption()?.id === 'gift-vouchers') {
			Basket.reset();
		}
		this.position();
		if (Basket.has_multi_basket) {
			Basket.setOrderType('table');
			if (this.props.location?.state?.saveOrderData) {
				this.setState({ showTableNumber: false });
			} else {
				this.setState({ showTableNumber: true });
				if (getConfig().general.hasServiceCharge && getConfig().flags.applyAutomaticServiceCharge) {
					const defaultServiceCharge =
						typeof getConfig().general.defaultServiceCharge == 'string' ? JSON.stringify(getConfig().general.defaultServiceCharge) : getConfig().general.defaultServiceCharge;
					Basket.setServicePercentage(parseInt(defaultServiceCharge[1] * 100));
				} else {
					Basket.setServicePercentage(0);
				}
			}
			let option = (getConfig().delivery || []).find((d) => d.id === Basket.order_type);
			Basket.setDeliveryOption(option);
			Basket.setCollectionTime(null);
			if (searchParams.get('t_id')) {
				const tableId = parseInt(searchParams.get('t_id'));
				let valid = true;
				if (this.state.tableNumberPicker || this.state.checkTablePlan) {
					const tableData = this.formatMultiVenueTableNumbers().filter((table) => table.value == tableId);
					valid = tableData.length > 0;
				}
				if (valid) {
					Basket.setTableNumber(tableId);
					this.setState({
						tableNumber: tableId,
						showTableNumber: false,
						pickTime: moment(Date.now()).format('HH:mm'),
					});
				}
			}
		} else {
			if (searchParams.get('bl_id')) {
				const deliveryOptions = getConfig().delivery.filter((deliveryOption) => deliveryOption.id === 'table');
				this.props.dispatch(setDeliveryOption(deliveryOptions[0]));
				Basket.setDeliveryOption(deliveryOptions[0]);
				this.setState({ useSearchParams: true, showTableNumber: true });
			}

			const { restaurants, profile } = this.props;
			Basket.setOrderType('table');
			if (this.state.selectedRestaurant) {
				const selectedRestaurant = restaurants.find((restaurant) => restaurant.id === this.state.selectedRestaurant);
				const orderType = getConfig().delivery.filter((el) => el.id === 'table');
				const orderTypeDisabled = orderType[0]?.isRemoved || orderType[0]?.isDisabled;
				if (selectedRestaurant.can_table_order && selectedRestaurant.is_published && !orderTypeDisabled) {
					Basket.setRestaurant(restaurants.find((restaurant) => restaurant.id === this.state.selectedRestaurant));
					this.selectRestaurant(restaurants.find((restaurant) => restaurant.id === this.state.selectedRestaurant).id);

					Basket.setServicePercentage(this.props.location.state.selectedRestaurant.service_charge_percentage);
					Basket.setCollectionTime(null);
					const tableNumbers = restaurants.find((restaurant) => restaurant.id === this.state.selectedRestaurant).table_plan_json;
					this.setState({
						totalTableNumbers: tableNumbers,
						pickTime: moment(Date.now()).format('HH:mm'),
					});
				} else {
					forwardTo('/history', { tab: 'order' });
					this.props.dispatch(showToast(this.props.__('This restaurant is currently not accepting orders'), 'warning'));
					Basket.reset();
				}
			} else if (searchParams.get('bl_id')) {
				const selectedRestaurant = this.props.restaurants.filter((restaurant) => restaurant.business_location_id === searchParams.get('bl_id'))[0];

				if (selectedRestaurant) {
					Basket.setRestaurant(selectedRestaurant);
					this.selectRestaurant(selectedRestaurant.id);
					Basket.setServicePercentage(selectedRestaurant.service_charge_percentage);
					Basket.setCollectionTime(null);
					const tableNumbers = restaurants.find((restaurant) => restaurant.id === selectedRestaurant.id).table_plan_json;
					if (searchParams.get('t_id') && this.state.tableNumberPicker) {
						const tableNumberValid = !!tableNumbers.find((number) => number.value === searchParams.get('t_id'));
						if (tableNumberValid) {
							this.setState({
								tableNumber: searchParams.get('t_id'),
								totalTableNumbers: tableNumbers,
								pickTime: moment(Date.now()).format('HH:mm'),
							});
						} else {
							this.props.dispatch(showToast('Please select different table', 'warning'));
						}
					}
				} else {
					const defaultRoute = getDefaultRoute(this.props.navConfig);
					forwardTo(defaultRoute.path);
					this.props.dispatch(showToast('Restaurant you selected is not available', 'warning'));
				}
			} else {
				const filteredRestaurants = restaurants.filter(
					(store) => store.opened && isDefined(store.can_table_order) && isDefined(store.is_published) && store.can_table_order && store.is_published,
				);
				if (filteredRestaurants.length > 0) {
					let byDistance = filteredRestaurants;
					byDistance.sort(function (a, b) {
						return a.distance - b.distance;
					});
					Basket.setRestaurant(byDistance[0]);
					this.selectRestaurant(byDistance[0].id);
				} else {
					this.setState({
						noTableRestaurants: true,
						selectedRestaurant: null,
						showTableNumber: false,
						tableNumber: null,
						totalTableNumbers: [],
					});
				}
			}
			let option = (getConfig().delivery || []).find((d) => d.id === Basket.order_type);
			Basket.setDeliveryOption(option);
			checkForDeliveryOption(Basket.getDeliveryOption(), '/order-to-table').then((deliveryOption) => {
				if (deliveryOption) {
					this.props.dispatch(getRestaurants());
					this.props.dispatch(setDeliveryOption(deliveryOption));
				}
				if (Basket.getMenu()) {
					this.setState({ selectedIkentooMenu: Basket.getMenu() });
				}

				const enabledRestaurants = restaurants.filter((store) => {
					if (isDefined(store.can_table_order) && isDefined(store.is_published)) {
						if (store.can_table_order && store.is_published) {
							return store;
						}
					}
				});
				if (enabledRestaurants.length === 1) {
					this.initValueOfSelectedRestaurant(enabledRestaurants, profile, deliveryOption);
				}
			});
		}
	}

	componentDidUpdate(prevProps, prevState) {
		const { restaurants } = this.props;

		if (this.state.isLocationAllowed !== prevState.isLocationAllowed && !this.state.useSearchParams) {
			this.props.dispatch(getRestaurants());
			const filteredRestaurants = restaurants.filter((store) => store.opened && isDefined(store.can_table_order) && isDefined(store.is_published) && store.can_table_order && store.is_published);
			if (filteredRestaurants.length > 0) {
				let byDistance = filteredRestaurants;
				byDistance.sort(function (a, b) {
					return a.distance - b.distance;
				});
				Basket.setRestaurant(byDistance[0]);
				this.selectRestaurant(byDistance[0].id);
			}
		}
		if (!Basket.has_multi_basket) {
			if (this.state.selectedRestaurant !== prevState.selectedRestaurant && this.state.selectedRestaurant) {
				const selectedRestaurant = restaurants.find((restaurant) => restaurant.id === this.state.selectedRestaurant);
				const tableNumbers = selectedRestaurant.table_plan_json;
				this.setState({
					totalTableNumbers: tableNumbers,
				});
			}
		}
	}

	initValueOfSelectedRestaurant = (restaurants, profile, deliveryOption) => {
		const stores = (restaurants || []).forEach((itm) => {
			if (itm?.id && itm?.name) {
				if (isDefined(itm.can_table_order) && isDefined(itm.is_published)) {
					if (itm.can_table_order && itm.is_published) {
						this.setState(
							{
								selectedRestaurant: itm.id,
								pickTime: moment(Date.now()).format('HH:mm'),
							},
							() => {
								Basket.reset(profile?.cardToken);
								Basket.setRestaurant(restaurants.find((restaurant) => restaurant.id === itm.id));
								if (getConfig().general.hasServiceCharge && getConfig().general.service_charge_modules?.includes('table')) {
									if (getConfig().flags.applyAutomaticServiceCharge) {
										if (Basket.getRestaurantServiceCharge()) {
											const service_charge_original = Basket.getRestaurantServiceCharge();
											Basket.setServicePercentage(parseInt(service_charge_original[1] * 100));
										} else {
											const defaultServiceCharge =
												typeof getConfig().general.defaultServiceCharge == 'string'
													? JSON.stringify(getConfig().general.defaultServiceCharge)
													: getConfig().general.defaultServiceCharge;
											Basket.setServicePercentage(parseInt(defaultServiceCharge[1] * 100));
										}
									} else {
										Basket.setServicePercentage(0);
									}
								} else {
									Basket.setServicePercentage(0);
								}
								Basket.setCollectionTime(null);
								Basket.setOrderType('table');
								Basket.setDeliveryOption(deliveryOption);
							},
						);
					}
				}
			}
			return null;
		});

		return stores;
	};

	selectRestaurant = (data) => {
		const { restaurants, profile } = this.props;
		const selectedRestaurantId = data;
		if (Basket.has_multi_basket) {
			Basket.setRestaurant(restaurants.find((restaurant) => restaurant.id === selectedRestaurantId));
			if (this.props.location?.state?.saveOrderData) {
				this.setState(
					{
						tableNumber: Basket.getTableNumber(),
						pickTime: moment(Date.now()).format('HH:mm'),
					},
					() => {
						this.chooseMenusForLocation();
					},
				);
			} else {
				this.chooseMenusForLocation();
			}
		} else {
			this.setState(
				{
					selectedRestaurant: selectedRestaurantId,
					pickTime: moment(Date.now()).format('HH:mm'),
					showTableNumber: true,
					selectedRestaurantFull: restaurants.find((restaurant) => restaurant.id === selectedRestaurantId),
				},
				() => {
					Basket.reset(profile.cardToken);
					Basket.setRestaurant(restaurants.find((restaurant) => restaurant.id === selectedRestaurantId));
					if (Basket.getRestaurant()) {
						if (getConfig().general.hasServiceCharge) {
							if (getConfig().flags.applyAutomaticServiceCharge) {
								if (Basket.getRestaurantServiceCharge()) {
									const service_charge_original = Basket.getRestaurantServiceCharge();
									Basket.setServicePercentage(parseInt(service_charge_original[1] * 100));
								} else {
									const defaultServiceCharge =
										typeof getConfig().general.defaultServiceCharge == 'string'
											? JSON.stringify(getConfig().general.defaultServiceCharge)
											: getConfig().general.defaultServiceCharge;
									Basket.setServicePercentage(parseInt(defaultServiceCharge[1] * 100));
								}
							} else {
								Basket.setServicePercentage(0);
							}
						} else {
							Basket.setServicePercentage(0);
						}
					}
					Basket.setCollectionTime(null);
					Basket.setDeliveryOption(this.props.deliveryOption);
					Basket.setOrderType('table');
				},
			);
		}
	};

	selectTableNumber = (event, data) => {
		const tableId = data.getVal();
		this.setState({
			tableNumber: tableId,
		});
	};

	formatDataForSelect = (stores, showAll = false) => {
		let arrForSelect = [];
		stores.forEach((store) => {
			if ((isDefined(store.can_table_order) && isDefined(store.is_published) && store.can_table_order) || (showAll && store.is_published)) {
				const currentDT = moment();
				let minDT = currentDT;
				if (store && isDefined(store.order_slot_lead_time)) {
					minDT.add(store.order_slot_lead_time, 'minutes');
				}
				const availableSlots = isTimeAvaibleInMenu(store.table_json_time_selector, currentDT);
				const storeOpened = isStoreOpened(store);
				store.opened = storeOpened && availableSlots;
				arrForSelect.push(store);
			}
		});
		return arrForSelect;
	};

	formatTableNumberForSelect = (selectedRestaurantId) => {
		const { __ } = this.props;
		let arrForSelect = [];
		if (selectedRestaurantId) {
			(this.state.totalTableNumbers || []).map((table) => {
				arrForSelect.push({ text: table.name, value: table.value });
			});
		}
		return [{ text: __(tableSelectPlaceholder), value: null }, ...arrForSelect];
	};

	getMenuForReorder = (menus, reorderItems) => {
		let menuForReorder = [];
		for (let i = 0; i < menus.length; i++) {
			let foundItems = 0;
			const categoryItems = menus[i].menuEntry || menus[i].menuEntryGroups || null;
			let items = Basket.flattenMenuItems(deepCopy(categoryItems));
			menus[i].flattenMenuItems = items;
			if (items.length > 0) {
				for (let j = 0; j < reorderItems.length; j++) {
					let foundItem = items.find((i) => i.sku === reorderItems[j].item.sku);
					if (foundItem) {
						foundItems = foundItems + 1;
					}
				}
			}
			menus[i].foundItems = foundItems;
			menuForReorder.push(menus[i]);
		}
		menuForReorder.sort((a, b) => b.foundItems - a.foundItems);
		if (menuForReorder[0] && menuForReorder[0].foundItems > 0) {
			return filterMenu(menuForReorder[0]);
		} else {
			return false;
		}
	};
	getKitchenStatusColor(status) {
		let color = '';
		switch (status) {
			case 0:
				color = 'success';
				break;
			case 1:
				color = 'warning';
				break;
			case 2:
				color = 'danger';
		}
		return color;
	}
	chooseMenusForLocation = async () => {
		const { __, restaurants, dispatch } = this.props;
		const { selectedRestaurant, tableNumber, pickTime } = this.state;
		this.setState({ continueButtonClicked: true, isSubmitRestaurantAlertOpen: false }, async () => {
			if (selectedRestaurant && tableNumber && pickTime) {
				const choosenRestaurant = restaurants.find((restaurant) => restaurant.id === selectedRestaurant);
				const businessLocationId = choosenRestaurant.business_location_id;
				let cutoffTimeRes = moment().unix();
				Basket.setCutoffTime(cutoffTimeRes);
				Basket.setTableNumber(tableNumber);
				// Basket.setProcessingFee();
				if (this.props.location.state && this.props.location.state.selectedRestaurant) {
					let ikentooMenusForLocation = await api.getIkentooMenusForLocation(businessLocationId);
					ikentooMenusForLocation = getMenusForSelectedTime(ikentooMenusForLocation, pickTime, choosenRestaurant.table_json_time_selector);
					if (ikentooMenusForLocation && isObject(ikentooMenusForLocation[0])) {
						let ikentooMenus = [];
						try {
							for (let i = 0; i < ikentooMenusForLocation.length; i++) {
								ikentooMenus.push(await api.getIkenooMenu(ikentooMenusForLocation[i].ikentooMenuId, businessLocationId));
							}
						} catch (error) {
							this.setState({ error: 'Get restaurant menu error.' });
							this.setState({ continueButtonClicked: false });
						}
						const reorderItems = this.props.location.state.selectedRestaurant.items;
						const menu = this.getMenuForReorder(ikentooMenus, reorderItems);
						if (menu) {
							dispatch({
								type: SET_RESTAURANT_PROP,
								key: 'ikentooMenu',
								value: menu,
							});
							if (menu.flattenMenuItems.length > 0) {
								let validationStatus = {
									notBasketEmpty: false,
									validationError: false,
								};
								reorderItems.map((newBasketItem) => {
									let foundItem = menu.flattenMenuItems.find((i) => i.sku === newBasketItem.item.sku);
									if (foundItem && Basket.isProductEnabled(foundItem)) {
										if (newBasketItem.selectedChoices[0]) {
											newBasketItem.selectedChoices[0] = newBasketItem.selectedChoices[0].filter((el) => Basket.isProductEnabled(el));
										}
										newBasketItem.item = foundItem;
										Basket.addToBasket(newBasketItem);
										let item = newBasketItem.item;
										let allergens = this.props.allergens;
										let profile = this.props.profile;
										let newArr = parseAllergenData(profile, item, allergens);
										let allergensCodes = newBasketItem.item?.itemRichData?.allergenCodes?.length > 0 ? newBasketItem.item.itemRichData.allergenCodes : [];
										if (allergensCodes.length > 0) {
											let allergensData = [{ allergens: newArr }, { sku: newBasketItem.item.sku }];
											Basket.setAllergen(allergensData);
										}
										validationStatus.notBasketEmpty = true;
									} else {
										validationStatus.validationError = true;
									}
								});
								if (validationStatus.notBasketEmpty) {
									if (validationStatus.validationError) {
										dispatch(showToast('Some items were not added to your basket as they are currently unavailable', 'warning'));
										this.setState({ continueButtonClicked: false });
									}
									forwardTo('/order-summary', {
										skipBackToThePreviousPage: false,
									});
									this.setState({ continueButtonClicked: false });
								} else {
									dispatch(showToast('Some items were not added to your basket as they are currently unavailable', 'warning'));
									this.setState({ continueButtonClicked: false });
								}
							}
						} else {
							dispatch(showToast('Menu not found', 'warning'));
							this.setState({ continueButtonClicked: false });
						}
					}
				} else {
					dispatch(
						getIkentooMenusForLocation(businessLocationId, {
							pickTime,
							json_time_selector: choosenRestaurant ? choosenRestaurant.table_json_time_selector : [],
						}),
					);
					this.setState({ continueButtonClicked: false });
				}
			} else if (!selectedRestaurant) {
				this.setState({ error: __('Please select location') });
				this.setState({ continueButtonClicked: false });
			} else {
				this.setState({ error: __('Please enter table number') });
				this.setState({ continueButtonClicked: false });
			}
		});
	};
	checkForTableDisplay = () => {
		const searchParams = new URLSearchParams(this.props.location.search);
		if (Basket.has_multi_basket) {
			if (this.props.location?.state?.saveOrderData || searchParams.get('t_id')) {
				return false;
			}
		} else {
			if ((searchParams.get('t_id') && !searchParams.get('bl_id')) || !this.state.useSearchParams) {
				return false;
			}
		}
		return true;
	};
	position = async () => {
		/* eslint-disable */
		navigator.geolocation.getCurrentPosition(
			(res) => {
				this.props.dispatch(
					setMyLocation({
						latitude: res?.coords?.latitude,
						longitude: res?.coords?.longitude,
					}),
				);
				this.setState({
					isLocationAllowed: true,
				});
			},
			() => {
				this.setState(
					{
						isLocationAllowed: false,
						showTableNumber: this.checkForTableDisplay(),
					},
					() => {
						this.props.dispatch(setMyLocation({ latitude: null, longitude: null }));
					},
				);
			},
		);
		/* eslint-enable */
	};

	changeIkentooMenus = (event) =>
		this.setState({ selectedIkentooMenu: event.detail.value, error: '' }, () => {
			Basket.setMenu(event.detail.value);
		});

	continueOnMenu = () => {
		const { restaurants, dispatch } = this.props;
		const { selectedIkentooMenu, selectedRestaurant } = this.state;
		if (selectedRestaurant && selectedIkentooMenu) {
			const choosenRestaurant = restaurants.find((restaurant) => restaurant.id === selectedRestaurant);
			const businessLocationId = choosenRestaurant.business_location_id;
			dispatch(getIkentooMenu(selectedIkentooMenu, businessLocationId));
		} else {
			this.setState({ error: 'Please select location menu' });
		}
	};
	backHandler = () => {
		if (this.state.showTableNumber || this.state.noTableRestaurants) {
			goBack();
		} else {
			this.setState({ showTableNumber: true });
		}
	};
	getRestautantDataMessage = (id) => {
		const restaurant = this.props.restaurants.filter((restaurant) => restaurant.id === id);
		return `<p><b>${restaurant[0]?.name}</b></p> \n <p>${restaurant[0]?.address}</p>`;
	};
	checkTableNumbers = () => {
		const tableId = this.state.tableNumber;
		let valid = true;
		if (this.state.tableNumberPicker || this.state.checkTablePlan) {
			const tableData = this.formatMultiVenueTableNumbers().filter((table) => table.value == tableId);
			valid = tableData.length > 0;
		}
		if (valid) {
			Basket.setTableNumber(tableId);
			this.setState({
				tableNumber: tableId,
				showTableNumber: false,
				pickTime: moment(Date.now()).format('HH:mm'),
				tableNumberError: false,
			});
		} else {
			this.setState({ tableNumberError: true });
		}
	};
	render() {
		const { __, restaurants, isChooseMenuModalOpen, ikentooMenusForLocation } = this.props;
		const { error, selectedIkentooMenu, pickTime, selectedRestaurant, showTableNumber, noTableRestaurants } = this.state;
		const stores = restaurants || [];
		const animationMenuClass = isChooseMenuModalOpen ? 'show-up' : '';
		const store = restaurants.find((restaurant) => restaurant.id === selectedRestaurant) || null;
		const menus = getMenusForSelectedTime(ikentooMenusForLocation, pickTime, store ? store.table_json_time_selector : []);
		const filteredRestaurants = restaurants.filter((store) => isDefined(store.can_table_order) && isDefined(store.is_published) && store.can_table_order && store.is_published);

		let formatedStores = this.formatDataForSelect(filteredRestaurants, noTableRestaurants);
		if (Basket.has_multi_basket && getConfig().flags.geolocationDisabled) {
			const stores = deepCopy(formatedStores);
			let openedRestaurants = stores.filter((store) => store.opened);
			let closedRestaurants = stores.filter((store) => !store.opened);
			openedRestaurants.sort((a, b) => {
				return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
			});
			openedRestaurants.sort((a, b) => a.sort_order - b.sort_order);
			closedRestaurants.sort((a, b) => {
				return a.name < b.name ? -1 : a.name > b.name ? 1 : 0;
			});
			closedRestaurants.sort((a, b) => a.sort_order - b.sort_order);
			formatedStores = [...openedRestaurants, ...closedRestaurants];
		}
		return (
			<Loading transparent>
				<Layout
					showLoginIcon={getConfig().flags.hasGuestCheckout && Basket.itemsCount() == 0}
					showHamburger={!this.state.showTableNumber && !this.state.noTableRestaurants}
					backHandler={this.backHandler}
					title={__('Order to table')}
					headerWithTitle={true}
					color="transparent"
				>
					<div className="flex-row-wrapper absolute-content">
						{!showTableNumber && (
							<>
								<div className="click-and-collect-title">
									<Title className="web-only">{__('Order to table')}</Title>
									<NormalText>{__('Select restaurant to collect your order')}</NormalText>
								</div>
								<div className="click-and-collect-locations">
									<IonList className={`box-wrapper ${getConfig().general.tiledRestaurantView ? 'tiled-box-wrapper' : ''}`}>
										{formatedStores.map((store, i) => (
											<>
												{getConfig().general.tiledRestaurantView ? (
													<IonItem
														className="pointer "
														disabled={!store.can_table_order || !store.opened || noTableRestaurants}
														onClick={() => {
															if (!Basket.has_multi_basket) {
																this.setState({
																	selectedRestaurant: store.id,
																	isSubmitRestaurantAlertOpen: true,
																	forwardToOrdering: false,
																});
															} else {
																this.setState({ selectedRestaurant: store.id }, () => {
																	this.selectRestaurant(store.id);
																	this.chooseMenusForLocation();
																});
															}
														}}
														lines="none"
														key={i}
													>
														<div style={{ width: '100%' }}>
															<img src={store.image} />
															<IonLabel>
																<NormalText>{__(store.name)}</NormalText>
																{(store.kitchen_status?.status == 1 || store.kitchen_status?.status == 2) && (
																	<SmallText className={`flex-wrapper flex-align-center block ${this.getKitchenStatusColor(store.kitchen_status.status)}-color`}>
																		<IonIcon style={{ width: '20px', height: '20px', marginRight: '5px' }} icon={warningOutline}></IonIcon>
																		{__(store.kitchen_status.text)}
																	</SmallText>
																)}
																<div className="restaurant-data" dangerouslySetInnerHTML={{ __html: store.info_html }}></div>
															</IonLabel>
														</div>

														<IonCheckbox checked={selectedRestaurant && store.id === selectedRestaurant} slot="start" color="primary" />
													</IonItem>
												) : (
													<IonItem
														key={i}
														disabled={!store.opened || noTableRestaurants}
														onClick={() =>
															this.setState({
																selectedRestaurant: store.id,
															})
														}
														lines="none"
														className="default-padding"
													>
														<IonLabel className="break-space">
															<NormalText className="primary-color">{__(store.name)}</NormalText>
															<SmallText className="block">{__(store.address)}</SmallText>
															<SmallText className={`${this.getKitchenStatusColor(store.kitchen_status.status)}-color`}>{store.kitchen_status.text}</SmallText>
														</IonLabel>
														<IonCheckbox checked={selectedRestaurant && store.id === selectedRestaurant} slot="start" color="primary" />
													</IonItem>
												)}
											</>
										))}
									</IonList>
								</div>
								{!getConfig().general.tiledRestaurantView && (
									<div className="click-and-collect-button-wrapper">
										<IonButton
											disabled={!selectedRestaurant}
											expand="block"
											color="primary"
											onClick={() => {
												if (Basket.has_multi_basket) {
													Basket.setRestaurant(this.props.restaurants.find((restaurant) => restaurant.id === this.state.selectedRestaurant));
													this.selectRestaurant(selectedRestaurant);
												} else {
													this.setState({ isSubmitRestaurantAlertOpen: true, forwardToOrdering: false });
												}
											}}
										>
											{__('Continue')}
										</IonButton>
									</div>
								)}
							</>
						)}
						{showTableNumber && (
							<>
								<div className="click-and-collect-title">
									<Title className="web-only">{__('Order to table')}</Title>
									{!Basket.has_multi_basket && <NormalText className="delivery-option-description">{__('Confirm your location and table')}</NormalText>}
								</div>
								<div className="order-to-table-locations ">
									{!Basket.has_multi_basket && (
										<>
											<IonList className="shadowed-box-light no-padding">
												<IonItem className="box-content unclickable" key={selectedRestaurant} lines="none">
													<div tabIndex="-1"></div>
													<IonCheckbox color="primary" slot="start" checked />
													<IonLabel className="break-space" color="dark">
														<NormalText className="primary-color">
															{this.state.selectedRestaurantFull ? this.state.selectedRestaurantFull.name : Basket.getRestaurant()?.name}
														</NormalText>
														<SmallText className="block">
															{this.state.selectedRestaurantFull ? this.state.selectedRestaurantFull.address : Basket.getRestaurant()?.address}
														</SmallText>
														<SmallText
															className={`${this.getKitchenStatusColor(
																this.state.selectedRestaurantFull
																	? this.state.selectedRestaurantFull.kitchen_status.status
																	: Basket.getRestaurant()?.kitchen_status.status,
															)}-color`}
														>
															{this.state.selectedRestaurantFull ? this.state.selectedRestaurantFull.kitchen_status.text : Basket.getRestaurant()?.kitchen_status.text}
														</SmallText>
													</IonLabel>
												</IonItem>
											</IonList>
											{filteredRestaurants.length > 1 ? (
												<IonButton
													expand="block"
													fill="clear"
													color="secondary"
													className="link underlined"
													onClick={() =>
														this.setState({
															selectedRestaurant: null,
															showTableNumber: false,
															tableNumber: null,
															totalTableNumbers: [],
														})
													}
												>
													{__('At a different location?')}
												</IonButton>
											) : (
												<Spacer />
											)}
										</>
									)}

									<div className={`input-field-container ${Basket.has_multi_basket ? 'multi-venue-table-picker' : ''}`}>
										<NormalText>{__('Table Number')}</NormalText>
										{Basket.has_multi_basket ? (
											<>
												{this.state.tableNumberPicker ? (
													<SelectOption
														inputStyle="box"
														display="inline"
														{...(getConfig().tablePlan && getConfig().tablePlan.length > 1 && { group: { header: true, groupWheel: false, clustered: false } })}
														data={this.formatMultiVenueTableNumbers()}
														value={this.state.tableNumber || null}
														onSet={(e, a) => this.selectTableNumber(e, a)}
														onInit={() => {
															const tableData = this.formatMultiVenueTableNumbers();
															if (tableData.length > 0) {
																if (!this.state.tableNumber && tableData[0]) {
																	this.setState({
																		tableNumber: tableData[0].value,
																	});
																	Basket.setTableNumber(tableData[0].value);
																}
															}
														}}
													/>
												) : (
													<IonItem lines="none" className="input-field-wrapper">
														<IonInput
															className="table-number-input"
															onIonChange={(e) =>
																this.setState({
																	tableNumber: e.target.value,
																})
															}
															value={this.state.tableNumber}
															type="number"
															placeholder={__('Enter table number')}
														/>
													</IonItem>
												)}
											</>
										) : (
											<IonItem lines="none" className="input-field-wrapper">
												{this.state.tableNumberPicker ? (
													<SelectOption
														display="center"
														label="Table Number"
														inputStyle="box"
														setText={__('OK')}
														cancelText={__('Cancel')}
														data={this.formatTableNumberForSelect(this.state.selectedRestaurant)}
														value={this.state.tableNumber}
														onSet={(e, a) => this.selectTableNumber(e, a)}
													/>
												) : (
													<>
														<IonInput
															className="table-number-input"
															onIonChange={(e) =>
																this.setState({
																	tableNumber: e.target.value,
																})
															}
															value={this.state.tableNumber}
															type="text"
															placeholder={__('Enter table number')}
														/>
													</>
												)}
											</IonItem>
										)}
									</div>
									{this.state.tableNumberError && <FieldError className="field-error" value={__('Invalid table number. Please double-check and try again.')} />}
									<Spacer size={2} />

									{getConfig().frenchDisclaimer?.disclaimerImage && (
										<div className="disclaimer-wrapper">
											<img src={getConfig().frenchDisclaimer?.disclaimerImage}></img>
										</div>
									)}

									<div className="click-and-collect-button-wrapper">
										<IonButton
											disabled={this.state.tableNumber ? false : true}
											expand="block"
											color="primary"
											className={this.state.continueButtonClicked ? 'unclicked' : ''}
											onClick={() => {
												if (Basket.has_multi_basket) {
													if (this.state.checkTablePlan) {
														this.checkTableNumbers();
													} else {
														Basket.setTableNumber(this.state.tableNumber);

														this.setState({
															showTableNumber: false,
															pickTime: moment(Date.now()).format('HH:mm'),
														});
													}
												} else {
													if (filteredRestaurants.length > 1) {
														this.setState({ isSubmitRestaurantAlertOpen: true, forwardToOrdering: true });
													} else {
														this.chooseMenusForLocation();
													}
												}
											}}
										>
											{__('Continue')}
										</IonButton>
									</div>
								</div>
							</>
						)}
					</div>

					{!menus.length ? null : (
						<>
							<div
								className="click-collect-pickers-backdrop"
								style={{ display: isChooseMenuModalOpen ? '' : 'none' }}
								onClick={() => this.props.dispatch(setCommonModal('isChooseMenuModalOpen', false))}
							></div>
							<div className={`click-collect-dialog ${animationMenuClass}`}>
								{/* <Loading transparent> {null} </Loading> */}
								<div className="click-collect-dialog-layout sc-ion-modal-md">
									<div className="click-collect-dialog-header">
										<Title>{__('Choose menu')}</Title>
									</div>
									<div
										className="click-collect-dialog-closer"
										style={{ position: 'absolute', right: 0, top: 0 }}
										onClick={() => this.props.dispatch(setCommonModal('isChooseMenuModalOpen', false))}
									>
										<ion-icon name="close" role="img" className="md hydrated" aria-label="close"></ion-icon>
									</div>
									<div className="click-collect-dialog-content">
										<IonList lines="none" className="box-wrapper">
											<IonRadioGroup onIonChange={this.changeIkentooMenus} value={selectedIkentooMenu}>
												{menus.length === 0 ? (
													<NoData />
												) : (
													menus.map((menu) => {
														const { ikentooMenuId, menuName } = menu;
														return (
															<IonItem key={ikentooMenuId} lines="none">
																<NormalText className="ion-text-wrap" color="dark">
																	{menuName}
																</NormalText>
																<IonRadio color={isWebConfig() ? 'primary' : 'white'} slot="start" value={ikentooMenuId} />
															</IonItem>
														);
													})
												)}
											</IonRadioGroup>
										</IonList>
									</div>
									<div className="click-collect-dialog-action">
										{/* {error ? (
											<IonItem>
												<div tabIndex="-1"></div>
												<FieldError className="field-error" value={__(error)} />
											</IonItem>
										) : null} */}
										<IonButton
											disabled={pickTime && menus.length > 0 ? false : true}
											expand="block"
											color="primary"
											className="uppercase okx-font-secondary"
											onClick={() => this.continueOnMenu()}
										>
											{__('Continue')}
										</IonButton>
									</div>
								</div>
							</div>
						</>
					)}
				</Layout>
				<IonAlert
					isOpen={this.state.isSubmitRestaurantAlertOpen}
					onDidDismiss={() => this.setState({ isSubmitRestaurantAlertOpen: false })}
					header={__('Please confirm you are in this location')}
					message={__(this.getRestautantDataMessage(selectedRestaurant))}
					buttons={[
						{
							text: this.state.forwardToOrdering ? __('Change') : __('Cancel'),
							role: 'cancel',
							cssClass: 'secondary',
							handler: () => (this.state.forwardToOrdering ? this.setState({ showTableNumber: false, forwardToOrdering: false }) : this.setState({ isSubmitRestaurantAlertOpen: false })),
						},
						{
							text: __('Confirm'),
							role: 'submit',
							cssClass: 'secondary',
							handler: () => (this.state.forwardToOrdering ? this.chooseMenusForLocation() : this.selectRestaurant(selectedRestaurant)),
						},
					]}
				/>
			</Loading>
		);
	}
}

const stateToProps = (state) => {
	const { deliveryOption } = state.orders;
	const { restaurants, ikentooMenusForLocation } = state.restaurants;
	const { isChooseMenuModalOpen, navConfig } = state.common;
	return {
		navConfig,
		deliveryOption,
		restaurants: restaurants || [],
		profile: state.profile.profile,
		isChooseMenuModalOpen: isChooseMenuModalOpen,
		ikentooMenusForLocation: ikentooMenusForLocation || [],
	};
};

export default connect(stateToProps)(withTranslation(OrderToTable));

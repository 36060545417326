import { IonButton, IonContent } from '@ionic/react';
import React from 'react';
import { getConfig } from '../../appConfig';
import { NormalText, Spacer, Title } from '../../components/common';
import Incrementer from '../../components/incrementer';
import Layout from '../../components/layout';
import Loading from '../../components/spinner';
import Basket from '../../lib/basket';
import { withTranslation } from '../../lib/translate';
import { isWebConfig } from '../../lib/utils';
import './index.css';

class ServiceChargeRaw extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			serviceCharge: 0,
			serviceChargePercentage: 0,
		};
	}
	componentDidMount() {
		const basketInstance = this.props.basketInstance || Basket;
		const currentServicePercentage = basketInstance.getServicePercentage();
		this.setState({
			serviceCharge: basketInstance._getServiceCharge(),
			serviceChargePercentage: currentServicePercentage,
		});
	}
	onIncrementerUpdate = (serviceChargePercentage) => {
		const basketInstance = this.props.basketInstance || Basket;
		basketInstance.setServicePercentage(serviceChargePercentage);
		this.setState({
			serviceChargePercentage,
			serviceCharge: basketInstance._getServiceCharge(),
		});
	};

	setServiceCharge(num) {
		const basketInstance = this.props.basketInstance || Basket;
		basketInstance.setServicePercentage(num);
		this.setState({
			serviceChargePercentage: num,
			serviceCharge: basketInstance._getServiceCharge(),
		});
	}
	applyServiceCharge() {
		if (isWebConfig() && this.props.serviceChargeModalOpen) {
			this.props.handleServiceChargeModal(false);
		} else {
			this.props.history.push('/order-summary', {
				serviceChargePercentage: this.state.serviceChargePercentage,
			});
		}
	}
	clearServiceCharge() {
		const basketInstance = this.props.basketInstance || Basket;
		basketInstance.setServicePercentage(0);
		this.setState({ serviceCharge: 0, serviceChargePercentage: 0 }, () => {
			this.applyServiceCharge();
		});
	}
	render() {
		const { __ } = this.props;
		const basketInstance = this.props.basketInstance || Basket;
		let service_charge_original;
		if (basketInstance.getRestaurantServiceCharge()) {
			service_charge_original = basketInstance.getRestaurantServiceCharge();
		} else {
			service_charge_original = typeof getConfig().general.defaultServiceCharge == 'string' ? JSON.stringify(getConfig().general.defaultServiceCharge) : getConfig().general.defaultServiceCharge;
		}

		const service_charge = service_charge_original.map((el) => parseInt(el * 100));
		const { serviceChargePercentage } = this.state;
		return (
			<IonContent>
				<div className="absolute-content flex-row-wrapper service-charge">
					<div className="scrollable-y checkout flex-row-wrapper ">
						<Title className="centered ">{__('Edit service charge')}</Title>
						<Spacer size={1} />
						<NormalText>{__('Had great service? We encourage our guests to leave a 10% service charge, however this is optional.')}</NormalText>
						<div className="service-charge-content ">
							<div className="service-charge-incrementer-wrapper">
								<p>
									{__('Total Order Value')} {basketInstance._getTotal(true)}
								</p>
								<Incrementer onUpdate={this.onIncrementerUpdate} quantity={serviceChargePercentage} step={1} allowNegative={false} note="%" color="secondary" />
								<p>
									{__('Service charge')} {basketInstance.getServiceCharge()}
								</p>
								<div className="incrementer-buttons">
									{service_charge.map((item) => {
										return (
											<IonButton
												key={item + 'service_charge'}
												expand="block"
												color="primary"
												onClick={() => {
													this.setServiceCharge(item);
												}}
											>
												<strong className="uppercase">{item}% </strong>
											</IonButton>
										);
									})}
								</div>
							</div>
						</div>
						<div className="flex-min service-charge-buttons">
							<IonButton expand="block" color="primary" onClick={this.applyServiceCharge.bind(this)}>
								<strong className="uppercase">{__('APPLY')}</strong>
							</IonButton>
							<IonButton expand="block" fill="clear" color="primary" className="link underlined" onClick={this.clearServiceCharge.bind(this)}>
								<strong>{__('No thanks')}</strong>
							</IonButton>
						</div>
					</div>
				</div>
			</IonContent>
		);
	}
}

export const ServiceCharge = withTranslation(ServiceChargeRaw);

const ServiceChargeWrapper = (props) => (
	<Loading>
		<Layout headerTitle={props.__('Redeem Points')}>
			<ServiceCharge {...props} />
		</Layout>
	</Loading>
);

export default withTranslation(ServiceChargeWrapper);

import React, { Component } from 'react';
import { withTranslation } from '../../lib/translate.js';
import { NormalText } from '../common/index.js';

class NoData extends Component {
	render() {
		const { __, label, className, style } = this.props;
		return (
			<NormalText className={`${className}`} style={{ textAlign: 'center', ...(style || {}) }}>
				{__(label || __('No data'))}
			</NormalText>
		);
	}
}

export default withTranslation(NoData);

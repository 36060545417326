import React from 'react';
import { connect } from 'react-redux';
import { IonButton, IonIcon, isPlatform, IonButtons, IonToolbar } from '@ionic/react';
import Loading from '../../components/spinner';
import Layout from '../../components/layout';
import { withTranslation } from '../../lib/translate';
import { checkmarkCircle, closeCircleSharp } from 'ionicons/icons';
import './index.css';
import { Title, NormalText, Subtitle, Spacer } from '../../components/common';
import { forwardTo } from '../../lib/utils';
import { Capacitor, Plugins } from '@capacitor/core';
const { Browser } = Plugins;

const isWeb = () => Capacitor.platform === 'web';

const MembershipCompleted = ({ __, isMenuFlow, clientProfile }) => {
	const handleMembership = () => {
		if (!isWeb()) {
			Browser.close();
		}
		forwardTo('/membership');
	};

	const handleCheckout = () => {
		forwardTo('/membership');
	};
	return isPlatform('android') && !isWeb() ? (
		<>
			{window.location.href.includes('canceled=true') ? (
				<>
					<div className="membership-message">
						<IonToolbar>
							<IonButtons className="image-button-wrapper" color="primary">
								<IonButton
									style={{
										backgroundImage: `url(${clientProfile.logo_image})`,
										backgroundSize: 'auto 100%',
										backgroundPosition: 'center',
										backgroundRepeat: 'no-repeat',
									}}
									className="image-button"
									color="primary-shade"
								/>
							</IonButtons>
						</IonToolbar>
						<IonIcon expand="block" color="danger" icon={closeCircleSharp} className="success-icon" />
						<Title>{__('Payment Canceled')}</Title>
						<Spacer />

						<Title>{__('Please close this page')}</Title>
					</div>
				</>
			) : (
				<>
					<div className="membership-message">
						<IonToolbar>
							<IonButtons className="image-button-wrapper" color="primary">
								<IonButton
									style={{
										backgroundImage: `url(${clientProfile.logo_image})`,
										backgroundSize: 'auto 100%',
										backgroundPosition: 'center',
										backgroundRepeat: 'no-repeat',
									}}
									className="image-button"
									color="primary-shade"
								/>
							</IonButtons>
						</IonToolbar>
						<IonIcon expand="block" color="success" icon={checkmarkCircle} className="success-icon" />
						<Title>{__('Success')}</Title>
						<NormalText tag="div" className="description">
							{__('Thanks for joining')} {clientProfile.buisiness_name} {'Club!'}
						</NormalText>
						<Spacer />
						<Title>{__('Please close this page')}</Title>
					</div>
				</>
			)}
		</>
	) : (
		<>
			{window.location.href.includes('canceled=true') ? (
				<Loading transparent>
					<Layout hideSecondToolbar={true} color="transparent" headerTitle={__('Membership Canceled')}>
						<div className="absolute-content flex-row-wrapper">
							<div className="scrollable-y ion-text-center">
								<IonIcon expand="block" color="danger" icon={closeCircleSharp} className="success-icon" />
								<Title>{__('Payment Canceled')}</Title>
							</div>
							<div className="flex-min">
								<IonButton color="primary" expand="block" className={'checkout-btn '} onClick={handleCheckout}>
									{__('Go back')}
								</IonButton>
							</div>
						</div>
					</Layout>
				</Loading>
			) : (
				<Loading transparent>
					<Layout hideSecondToolbar={true} color="transparent" headerTitle={__('Membership Completed')}>
						<div className="absolute-content flex-row-wrapper">
							<div className="scrollable-y ion-text-center">
								<IonIcon expand="block" color="success" icon={checkmarkCircle} className="success-icon" />
								<Title>{__('Success')}</Title>
								<NormalText tag="div" className="description">
									{__('Thanks for joining')} {clientProfile.buisiness_name} {'Club!'}
								</NormalText>
							</div>
							<div className="flex-min">
								{/* {isMenuFlow ? ( */}
								<IonButton color="primary" expand="block" className={'checkout-btn '} onClick={handleMembership}>
									{__('View My Membership')}
								</IonButton>
								{/* ) : (
							<IonButton color="primary" expand="block" className={'checkout-btn '} onClick={handleCheckout}>
								{__('Back to Checkout')}
							</IonButton>
						)} */}
							</div>
						</div>
					</Layout>
				</Loading>
			)}
			;
		</>
	);
};

const mapStateToProps = (store) => ({
	isMenuFlow: store.subscription.flow === 'menu_flow',
	clientProfile: store.common.clientProfile,
});

export default connect(mapStateToProps)(withTranslation(MembershipCompleted));

import { call, put, select, takeEvery } from 'redux-saga/effects';
import api from '../../lib/api';
import { GET_PROFILE, SET_PROFILE_PROP } from '../profile/constants';
import {
	CANCEL_USER_SUBSCRIPTION,
	FETCH_SUBSCRIPTIONS,
	FETCH_SUBSCRIPTIONS_OK,
	PAY_MEMBERSHIP,
	REACTIVATE_USER_SUBSCRIPTION,
	PAY_MEMBERSHIP_OK,
	PAY_MEMBERSHIP_FAILED,
	CREATE_SUBSCRIPTION,
} from './constants';
import { forwardTo } from '../../lib/utils';
import { showToast } from '../common/actions';
import { getConfig } from '../../appConfig';
import { LOADING } from '../constants';

const getSubscriptionsSaga = function* () {
	const subscriptions = yield call(api.getSubscriptions);
	yield put({ type: GET_PROFILE });
	yield put({
		type: FETCH_SUBSCRIPTIONS_OK,
		key: 'subscriptions',
		value: subscriptions,
	});
};

const createSubscriptionSaga = function* (action) {
	try {
		const config = yield call(api.getFrontEndAppConfig);
		const result = yield call(api.createSubscription, {
			data: action.value,
			location: config.front_end_app_config.general.membershipLocationId,
		});
		window.open(result.data.checkout.url, '_self');
	} catch (error) {
		yield put(showToast(error.response.data.status, 'danger'));
	}
};

const cancelSubscriptionSaga = function* () {
	const { profile } = yield select();
	profile.profile.active_subscription = {
		...profile.profile.active_subscription,
		cancellation_pending: true,
	};
	const result = yield call(api.cancelUserSubscription);
	if (result.status === 201) {
		yield put({ type: SET_PROFILE_PROP, key: 'profile', value: profile.profile });
	}
};

const reactivateSubscriptionSaga = function* () {
	const { profile } = yield select();
	profile.profile.active_subscription = {
		...profile.profile.active_subscription,
		cancellation_pending: false,
	};
	const result = yield call(api.cancelUserSubscription, {
		cancel: false,
	});
	if (result.status === 201) {
		yield put({
			type: SET_PROFILE_PROP,
			key: 'profile',
			value: profile.profile,
		});
	}
};

const payMembershipSaga = function* (action) {
	try {
		const data = yield call(api.payMembership, action.value);
		yield put({
			type: PAY_MEMBERSHIP_OK,
			data,
		});
		yield put({
			type: GET_PROFILE,
		});
		yield call(forwardTo, '/membership-completed', {
			completedOrder: true,
		});
	} catch (error) {
		yield put({
			type: PAY_MEMBERSHIP_FAILED,
			error,
		});
	}
};

export default function* subscriptionSagas() {
	yield takeEvery(FETCH_SUBSCRIPTIONS, getSubscriptionsSaga);
	yield takeEvery(CANCEL_USER_SUBSCRIPTION, cancelSubscriptionSaga);
	yield takeEvery(REACTIVATE_USER_SUBSCRIPTION, reactivateSubscriptionSaga);
	yield takeEvery(PAY_MEMBERSHIP, payMembershipSaga);
	yield takeEvery(CREATE_SUBSCRIPTION, createSubscriptionSaga);
}

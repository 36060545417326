import { IonButton, IonIcon, IonInput } from '@ionic/react';
import React from 'react';
import Mobiscroll from '../../components/mobiscroll';
import moment from '../../lib/moment';
import { withTranslation } from '../../lib/translate';
import { isDefined } from '../../lib/utils';
import { beforeCloseTimePicker, beforeShowTimePicker } from '../../store/actions';
import { NormalText, StrongText } from '../common';
import './index.css';

const check = require('../../assets/images/Deli_Check.svg');
const wrong = require('../../assets/images/Deli_Wrong.svg');

const { DatePicker } = Mobiscroll;

class ValidateInput extends React.Component {
	state = {
		value: this.props.value || '',
		show: false,
	};

	componentDidMount() {
		if (this.props.name) {
			const valid = this.props.validatedData[this.props.name];
			this.setState({ show: valid });
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.value !== this.props.value) {
			this.setState({ value: this.props.value });
		}
		if (prevProps.validatedData[this.props.name] !== this.props.validatedData[this.props.name]) {
			if (this.props.name) {
				const valid = this.props.validatedData[this.props.name];
				this.setState({ show: valid });
			}
		}
	}

	togglePass = () => {
		const { type } = this.props;
		if (type && type === 'email') {
			const value = isDefined(this.state.value) && /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i.test(this.state.value);
			this.setState({ show: value });
		} else {
			const value = isDefined(this.state.value) && this.state.value !== '';
			this.setState({ show: value });
		}
	};

	handlePeriodicalSaga = (flag) => {
		const { dispatch } = this.props;
		dispatch({ type: 'SET_COMMON_PROP', key: 'emitterFlag', value: flag });
	};

	onChange = (e) => {
		const { onIonChange } = this.props;
		this.setState({ value: e.target.value }, () => {
			this.togglePass();
		});
		if (onIonChange) {
			onIonChange(e);
		}
	};

	render() {
		const { show, value } = this.state;
		const { __, type, ...rest } = this.props;
		const yesterday = new Date(new Date().setDate(new Date().getDate() - 1));
		const defaultDateValue = moment().subtract(18, 'years');
		return (
			<div className="okx-validate-input-wrapper">
				<div className="okx-validate-input-holder">
					{type === 'date' ? (
						<div className="date-picker-wrapper">
							<DatePicker
								className="data-picker-input"
								display="bottom"
								max={yesterday}
								setText="Done"
								defaultValue={defaultDateValue}
								onSet={(e, a) => this.onChange({ target: { value: a.element.value } })}
								onBeforeShow={() => this.props.dispatch(beforeShowTimePicker())}
								{...rest}
								value={value}
								onClose={() => {
									this.props.dispatch(beforeCloseTimePicker());
									this.handlePeriodicalSaga(true);
								}}
								onShow={() => this.handlePeriodicalSaga(false)}
							/>
						</div>
					) : null}
					{type === 'text' ? <IonInput {...rest} value={value} onIonChange={this.onChange}></IonInput> : null}
					{type === 'email' ? <IonInput readonly {...rest} value={value} onIonChange={this.onChange}></IonInput> : null}
					{type === 'email_verified' ? (
						<>
							{show ? (
								<NormalText>{__('Email Address Verified')}</NormalText>
							) : (
								<StrongText className="pointer bold underlined primary-color" onClick={() => this.props.onButtonClicked()}>
									{__('Resend address validation email')}
								</StrongText>
							)}
						</>
					) : null}
				</div>
				<IonButton fill="clear" size="small" onTouchStart={() => this.togglePass()} onTouchEnd={() => this.togglePass()} tabindex="-1">
					<IonIcon slot="icon-only" icon={show ? check : wrong} />
				</IonButton>
			</div>
		);
	}
}

export default withTranslation(ValidateInput);

import { FETCH_SUBSCRIPTIONS, SUBSCRIBE, CANCEL_USER_SUBSCRIPTION, REACTIVATE_USER_SUBSCRIPTION, PAY_MEMBERSHIP, SET_SUBSCRIPTION_FLOW, CREATE_SUBSCRIPTION } from './constants';

export const getSubscriptions = () => ({ type: FETCH_SUBSCRIPTIONS });
export const subscribe = (subscription) => ({ type: SUBSCRIBE, subscription });
export const unsubscribe = () => ({ type: CANCEL_USER_SUBSCRIPTION });
export const reactivateSubscription = () => ({ type: REACTIVATE_USER_SUBSCRIPTION });
export const payMembership = (membership) => ({ type: PAY_MEMBERSHIP, value: membership });
export const setMenuSubscriptionFlow = () => ({ type: SET_SUBSCRIPTION_FLOW, value: 'menu_flow' });
export const setOrderSubscriptionFlow = () => ({
	type: SET_SUBSCRIPTION_FLOW,
	value: 'order_flow',
});
export const createSubscription = (subscription) => ({
	type: CREATE_SUBSCRIPTION,
	value: subscription,
});

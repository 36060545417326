import React, { Component } from 'react';
import { SmallText, Spacer, Subtitle, Title } from '../common';
import { withTranslation } from '../../lib/translate';
import moment from 'moment';
import { getConfig } from '../../appConfig';
import './index.css';
import api from '../../lib/api';

class KioskCodeGenerator extends Component {
	constructor(props) {
		super(props);

		this.state = {
			generatedCode: '',
			timestamp: this.getCurrentTimestamp(),
			timer: 62,
		};
	}

	componentDidMount() {
		if (this.props.showCode) {
			this.fetchCode();
			this.interval = setInterval(() => {
				this.setState((prevState) => ({ timer: prevState.timer - 1 }));
				if (this.state.timer === 0) {
					this.fetchCode();
					this.setState({
						timestamp: this.getCurrentTimestamp(),
						timer: 62,
					});
				}
			}, 1000);
		}
	}
	getCurrentTimestamp() {
		return moment().tz(getConfig().timezone).format('h:mm A');
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	async fetchCode() {
		const code = await api.refreshScanToken();
		this.setState({ generatedCode: code[0] });
	}
	generateCodeClass = (timer) => {
		if (timer < 35 && timer > 15) {
			return 'warning';
		}
		if (timer <= 15) {
			return 'danger';
		}
		return 'success';
	};
	render() {
		const { __, showCode } = this.props;
		const { timestamp, generatedCode, timer } = this.state;
		return (
			<div className="box-content loyalty-code-generator">
				<div>
					<Subtitle className="bold">{__('Kiosk code')}</Subtitle>
					{showCode ? <SmallText>{`${__('Generated')} ${timestamp}`}</SmallText> : <SmallText>{`${__('Verify your account to link to kiosk')}`}</SmallText>}
				</div>
				{showCode && (
					<div>
						<Title className={this.generateCodeClass(timer)}>
							<b>{generatedCode.slice(0, 3)}</b>
							<b>{generatedCode.slice(3, 6)}</b>
						</Title>
					</div>
				)}
			</div>
		);
	}
}

export default withTranslation(KioskCodeGenerator);
